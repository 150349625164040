import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home'
import Privacy from './components/Privacy'
import tc from './components/tc'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            name: 'Home',
            path: '/',
            component: Home
        },
        {
            name: 'Privacy',
            path: '/Privacy',
            component: Privacy
        },
        {
            name: 'Terms',
            path: '/tc',
            component: tc
        },
        {
            path: '*',
            component: Home
        }
    ]
})
