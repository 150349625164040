<template>
    <div class="bg-dark fixed top-0 w-full z-1">
        <div
            class="nav flex px-120px py-16px justify-between items-center w-full ipad:(px-24px py-16px) mobile:(px-16px py-0)"
        >
            <router-link to="/">
                <img class="h-40px" src="../../../img/home_log.svg" />
            </router-link>
            <div v-if="$route.name === 'Home'" class="flex gap-32px mobile:(hidden)">
                <span v-for="(link, i) in navigation" :key="i">
                    <button @click="goTo(link, 'pc')" class="link">{{ link }}</button>
                </span>
            </div>
            <div v-if="$route.name === 'Home'" class="hidden mobile:block py-12px">
                <img v-if="!menu" class="w-40px" @click="openMenu" src="../../../img/menu.svg" />
                <img v-else class="w-40px" @click="openMenu" src="../../../img/close.svg" />
            </div>
        </div>
        <div
            v-if="menu"
            class="absolute h-screen inset-0 -z-1 bg-dark flex flex-col items-center justify-center gap-56px"
        >
            <span v-for="(link, i) in navigation" :key="i">
                <button @click="goTo(link, '')" class="link text-light text-base-24px">
                    {{ link }}
                </button>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NavBar',
    props: {
        navigation: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            menu: false
        }
    },
    methods: {
        openMenu() {
            this.menu = !this.menu
        },
        goTo(link, pc) {
            // will scroll to the selected section
            document.getElementById(link).scrollIntoView()
            if (!pc) this.menu = !this.menu
        }
    }
}
</script>
<style></style>
