import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '../styleguide.css'
import '../globals.css'
import 'windi.css'
import iconSvg from '/src/components/iconSvg/index.vue'
import { Collapse } from 'vue-collapsed'

Vue.component('icon-svg', iconSvg)
Vue.component('collapse', Collapse)
Vue.config.productionTip = false
new Vue({
    render: (h) => h(App),
    router
}).$mount('#app')
